<template>
  <ApiLoadingController class="map-wrapper" :fetch="fetch" :params="params">
    <template #default="{ data }">
      <MapController v-bind="data" :initial-state="initialState" @update:initialState="updateInitialState" />
    </template>
  </ApiLoadingController>
</template>

<script>
import { mapGetters } from 'vuex'

import mapPage from '@/pages/mixins/mapPage.js'

import ApiLoadingController from '../../components/controllers/ApiLoadingController.vue'
import MapController from '../../components/Map/MapController.vue'
import genRoundId from '../../components/Map/utils/genRoundId.js'
import { convertMinimapData } from '../../utils/convertMinimapData.js'
import prepareMinimapDetectedData from '../../utils/prepareMinimapDetectedData.js'
import processMapToolData from '../../utils/processMapToolData.js'

const GRID_WIN_TYPE_OUTCOME = {
  bombExploded: 'Detonate',
  bombDefused: 'Defuse',
  opponentEliminated: 'Elimination',
  timeExpired: 'Time',
}

export default {
  name: 'GridLiveMapPage',
  mixins: [mapPage],
  components: { MapController, ApiLoadingController },
  props: {
    id: Number,
    apiKey: String,
  },
  computed: {
    ...mapGetters({
      abilitiesByGridName: 'static/abilitiesByGridName',
      agentsById: 'static/agentsById',
      agentsByModelName: 'static/agentsByModelName',
      agentsByName: 'static/agentsByName',
      agentsByNameLowercase: 'static/agentsByNameLowercase',
      agentsList: 'static/agentsList',
      gearsList: 'static/gearsList',
      mapsByNameLowecase: 'static/mapsByNameLowecase',
      mapsByUrl: 'static/mapsByUrl',
      weaponsList: 'static/weaponsList',
      weaponsByNameLowercase: 'static/weaponsByNameLowercase',
    }),
    params() {
      return { id: this.id }
    },
  },
  methods: {
    createBookmark() {},
    async fetch({ id }) {
      const [{ seriesState, killfeed, livePlayers }, mlData, streamMeta] = await Promise.all([
        (async id => {
          const res = await fetch(
            `https://o2st37x4js5ot5ko2kn3snx7oq0ifgck.lambda-url.us-east-1.on.aws/?seriesId=${encodeURIComponent(id)}`
          )
          return res.json()
        })(id),
        (async () => {
          try {
            const res = await fetch('/test-ml/matches')
            // const res = await fetch('/testml-matches.json')
            return res.json()
          } catch (e) {
            console.warn(e)
          }
        })(),
        (async () => {
          try {
            const res = await fetch('/test-ml/metadata')
            return res.json()
          } catch (e) {
            console.warn(e)
          }
        })(),
      ])
      const calcOutcome = (roundId, teams) => {
        const winner = teams.find(t => t.won)
        if (winner && winner.winType) {
          if (!GRID_WIN_TYPE_OUTCOME[winner.winType]) {
            console.warn('unknown win type', winner.winType)
          } else {
            return GRID_WIN_TYPE_OUTCOME[winner.winType]
          }
        }

        const def = teams.find(t => t.side === 'defender')
        const atk = teams.find(t => t.side === 'attacker')
        if (def.won) {
          if (def.objectives.some(({ id }) => id === 'defuseBomb')) {
            return 'Defuse'
          }
          if (livePlayers[roundId]?.[atk.id] != null) {
            if (livePlayers[roundId][atk.id] > 0) {
              return 'Time'
            }
          } else {
            if (def.kills < 5) {
              return 'Time'
            }
          }
        } else if (atk.won) {
          if (atk.objectives.some(({ id }) => id === 'explodeBomb')) {
            return 'Detonate'
          }
        }

        return 'Elimination'
      }

      console.log('mlData', mlData)
      const lastMlGameIdx = mlData?.findLastIndex(
        match => seriesState.games.find(g => g.id === match.id) && match.rounds.some(r => r.inferences?.minimap)
      )
      console.log('lastMlGameIdx', lastMlGameIdx)
      const lastMlGame = mlData?.[lastMlGameIdx]

      console.log('seriesState', seriesState)
      const lastGameState =
        lastMlGameIdx != null && lastMlGameIdx !== -1
          ? seriesState.games[lastMlGameIdx]
          : seriesState.games.findLast(game => game.started)
      console.log('lastGameState', lastGameState)

      const mapData = this.mapsByNameLowecase[lastGameState.map.name]
      console.log('mapData', mapData)
      const map = mapData.id
      // const rotateDeg = mapData.rotate_deg || 0

      const firstSegment = lastGameState.segments.reduce((min, cur) =>
        min.sequenceNumber < cur.sequenceNumber ? min : cur
      )
      const matchTeams = await Promise.all(
        lastGameState.teams
          .map(gameTeam => ({
            gameTeam,
            firstRoundTeam: firstSegment.teams.find(t => t.id === gameTeam.id),
            seriesTeam: seriesState.teams.find(t => t.id === gameTeam.id),
          }))
          .map(async ({ gameTeam, firstRoundTeam, seriesTeam }) => ({
            id: gameTeam.id,
            abbr: gameTeam.name,
            name: gameTeam.name,
            grid: firstRoundTeam.side === 'defender' ? 'Blue' : 'Red',
            team_side: firstRoundTeam.side === 'defender' ? 'Blue' : 'Red',
            rounds_won: gameTeam.score,
            image: seriesTeam?.logoUrl,
            players: gameTeam.players
              .filter(gamePlayer => gamePlayer.character)
              .map(gamePlayer => ({
                id: gamePlayer.id,
                puuid: gamePlayer.id,
                name: gamePlayer.name,
                game_name: gamePlayer.name,
                agent: this.agentsByNameLowercase[gamePlayer.character.id].id,
                agent_id: this.agentsByNameLowercase[gamePlayer.character.id].id,
                team_side: firstRoundTeam.side === 'defender' ? 'Blue' : 'Red',
              })),
          }))
      )
      console.log('matchTeams', matchTeams)

      console.log('livePlayers', livePlayers)

      const matchesData = [
        {
          id: lastMlGame?.id || lastGameState.id,
          created: Date.now(), // TODO: extract from events
          teams: matchTeams,
          rounds: lastGameState.segments
            .map(segment => ({ segment, mlRound: lastMlGame?.rounds.find(r => r.num === segment.sequenceNumber) }))
            .filter(({ segment }) => segment && segment.started && segment.finished)
            .map(({ segment, mlRound }) => ({
              round_num: segment.sequenceNumber - 1,
              round_length_millis: mlRound
                ? new Date(mlRound.end).getTime() - new Date(mlRound.start).getTime()
                : 100000,
              result_code: calcOutcome(genRoundId(lastGameState.id, segment.sequenceNumber - 1), segment.teams),
              teams: segment.teams
                .map(roundTeam => ({ roundTeam, matchTeam: matchTeams.find(t => t.id === roundTeam.id) }))
                .map(({ roundTeam, matchTeam }) => ({
                  id: roundTeam.id,
                  grid: matchTeam.grid,
                  role: roundTeam.side === 'defender' ? 'def' : 'atk',
                  won: roundTeam.won,
                })),
              kills: killfeed[genRoundId(lastGameState.id, segment.sequenceNumber - 1)]?.map(event => ({
                ...event,
                round_time_millis: mlRound ? event.timestamp - new Date(mlRound.start).getTime() : event.timestamp,
                finishing_damage: this.weaponsByNameLowercase[Object.keys(event.weaponKills || {}).pop()]
                  ? {
                      damage_type: 'Weapon',
                      damage_item: this.weaponsByNameLowercase[Object.keys(event.weaponKills).pop()].id,
                    }
                  : {},
              })),
            })),
          //   // TODO: fill player stats
          //   // player_stats: extraData.finalData.roster
          //   //   .filter(player => player.wasAlive)
          //   //   .map(player => ({
          //   //     puuid: player.player_id,
          //   //     team_side:
          //   //       (extraData.finalData.rounds[1].team === 'attack' && player.teammate) ||
          //   //       (extraData.finalData.rounds[1].team !== 'attack' && !player.teammate)
          //   //         ? 'Red'
          //   //         : 'Blue',
          //   //     assists:
          //   //       extraData.finalData.rounds[roundNum + 1].phases.end.scoreboard_end.find(
          //   //         item => item.player_id === player.player_id
          //   //       )?.assists -
          //   //       (extraData.finalData.rounds[roundNum]?.phases.end.scoreboard_end.find(
          //   //         item => item.player_id === player.player_id
          //   //       )?.assists || 0),
          //   //     // TODO: compute this
          //   //     damages: [
          //   //       {
          //   //         bodyshots: 0,
          //   //         headshots: 0,
          //   //         legshots: 0,
          //   //         total: 0,
          //   //       },
          //   //     ],
          //   //     kills:
          //   //       extraData.finalData.rounds[roundNum + 1].phases.end.scoreboard_end.find(
          //   //         item => item.player_id === player.player_id
          //   //       ).kills -
          //   //       (extraData.finalData.rounds[roundNum]?.phases.end.scoreboard_end.find(
          //   //         item => item.player_id === player.player_id
          //   //       ).kills || 0),
          //   //     deaths:
          //   //       extraData.finalData.rounds[roundNum + 1].phases.end.scoreboard_end.find(
          //   //         item => item.player_id === player.player_id
          //   //       ).deaths -
          //   //       (extraData.finalData.rounds[roundNum]?.phases.end.scoreboard_end.find(
          //   //         item => item.player_id === player.player_id
          //   //       ).deaths || 0),
          //   //     loadout_value: 0,
          //   //     remaining: extraData.finalData.rounds[roundNum + 1].phases.shopping.scoreboard_end.find(
          //   //       item => item.player_id === player.player_id
          //   //     ).money,
          //   //     // TODO: get it from somewhere
          //   //     spent: 0,
          //   //     armor_id: null,
          //   //     weapon_id: null,
          //   //   })),
          // })),
          advancedData: lastMlGame?.rounds
            .filter(r => r.clips)
            .map(mlRound => ({
              round_num: mlRound.num - 1,
              minimap_video_url: `/test-ml/clips/${mlRound.clips.minimap}`,
              replay_video_url: `/test-ml/clips/${mlRound.clips.full}`,
              replay_video_start_time: streamMeta
                ? new Date(mlRound.start).getTime() - new Date(streamMeta.stream_start).getTime()
                : null,
              replay_video_end_time: streamMeta
                ? new Date(mlRound.end).getTime() - new Date(streamMeta.stream_start).getTime()
                : null,
            })),
          replay: {
            video_id: `/test-ml/stream`,
            video_platform: 'augment',
            cropper_status: 'succeeded',
            // TODO: set correct values
            video_start_time: 0,
            video_end_time: 10000000,
          },
          vod_status: 'succeeded',
        },
      ]
      console.log('matchesData', matchesData)

      const roundAdvancedPositionsData = {}
      const roundSmokesData = {}
      const roundUtilitiesData = {}
      const roundWallsData = {}
      // const orbsData = {}
      const utilitiesUsage = {}

      lastMlGame?.rounds.forEach(roundData => {
        if (!roundData) return
        if (!roundData.inferences?.minimap) return
        const vodProcessedData = {}

        const roundId = genRoundId(lastMlGame.id, roundData.num - 1)
        const teams = matchesData[0].rounds.find(round => round.round_num + 1 === roundData.num)?.teams
        if (!teams) return
        const minimapData = convertMinimapData(
          roundData.inferences.minimap,
          matchTeams.flatMap(team => team.players),
          teams,
          this.agentsList
        )

        vodProcessedData[roundId] = [
          // ...(minimapData?.player_locations?.map(location => {
          //   return { ...location, type: 'agent' }
          // }) || []),
          ...Object.entries(roundData.player_states).flatMap(([puuid, states]) =>
            states?.positions.map(pos => ({
              type: 'agent',
              puuid,
              location: { x: pos.xy[0], y: pos.xy[1] },
              round_time_millis: pos.t * 1000,
              track_id: puuid,
            }))
          ),
          ...(minimapData?.ability_locations?.map(location => {
            return { ...location, type: 'ability' }
          }) || []),
          ...(minimapData?.smoke_locations?.map(location => {
            return { ...location, type: 'smoke' }
          }) || []),
          ...(minimapData?.spike_locations?.map(location => {
            return { ...location, type: 'spike' }
          }) || []),
          ...(minimapData?.wall_vectors?.map(location => {
            return { ...location, type: 'wall' }
          }) || []),
        ]
        console.log(roundId, 'vodProcessedData', vodProcessedData)
        utilitiesUsage[roundId] = Object.entries(roundData.player_states).flatMap(([puuid, abilities]) =>
          Object.entries(abilities).flatMap(([abilityGridName, states]) => {
            const ability = this.abilitiesByGridName[abilityGridName]
            if (!ability) {
              console.warn('unknown ability', abilityGridName, 'for', puuid)
              return []
            }
            if (ability.slot === 'Ultimate') return []
            return states
              .map((state, idx, arr) => {
                const prevState = arr[idx - 1]
                if (!prevState || prevState.ready === state.ready) return
                return {
                  round_time_millis: state.t * 1000,
                  puuid,
                  utility: ability.slot.toLowerCase(),
                  count: state.ready ? 1 : 0,
                  oldCount: prevState.ready ? 1 : 0,
                  maxCount: 1,
                  oldMaXCount: 1,
                }
              })
              .filter(Boolean)
          })
        )
        console.log('utilitiesUsage', utilitiesUsage)

        const filter = () => true // raw => raw.round_time_millis <= maxTime
        const processedData = prepareMinimapDetectedData(vodProcessedData[roundId], {
          filter,
          includeSmokes: true,
          includeUtilities: true,
          includeWalls: true,
          mapRotatedDeg: 0,
        })
        console.log(roundId, 'processedData', processedData)
        if (!processedData) return

        roundAdvancedPositionsData[roundId] = processedData.positions
        roundSmokesData[roundId] = processedData.smokes
        roundUtilitiesData[roundId] = processedData.utilities
        roundWallsData[roundId] = processedData.walls
      })

      const data = await processMapToolData({
        staticData: { agentsData: this.agentsList, weaponsData: this.weaponsList, gearsData: this.gearsList },
        matchesData,
        filterSuspiciousAdvancedPositions: false,
        roundAdvancedPositionsData,
        excludeSomeAbilities: false,
        roundUtilitiesUsageData: utilitiesUsage,
        roundSmokesData,
        roundUtilitiesData,
        roundWallsData,
        hasKillLocations: false,
        reportMissingLocations: false,
      })

      window.mapData = data

      return {
        canAccessPlayback: true,
        canAccessUtilities: true,
        hasApiKills: false,
        hasApiPositions: false,
        hasEconomy: false,
        hasOutcome: false,
        hasPlants: false,
        hasReports: false,
        hasWins: false,
        hasVod: false,
        isScrim: false,
        createBookmarkApi: this.createBookmark,
        data: {
          ...data,
          map: mapData,
          matchesData: Object.fromEntries(matchesData.map(match => [match.id, Object.freeze(match)])),
        },
        map,
        notes: [],
        noteTags: this.noteTags,
        team: matchTeams[0].id,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map-wrapper,
.map-wrapper2 {
  position: relative;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  overflow: hidden;
}
</style>
